import Title from './subcomponents/title'
import HomeButtons from './subcomponents/button'

const home = () => {
	return (
		<div className="home">
			<Title />
			<HomeButtons />
		</div>
	)
}

export default home;