import BackgroundScene from './components/three.js/cube'
import Home from './components/home/home'
import './App.css' 

const App = () => {
  return (
		<>
			<Home />
			<BackgroundScene />
		</>
  )
}

export default App;