import React, { useRef } from 'react'
import { useFrame, Canvas } from '@react-three/fiber'

function Box(props) {
  const mesh = useRef()

  // Rotate mesh every frame, this is outside of React without overhead
  useFrame(() => {
    mesh.current.rotation.z = mesh.current.rotation.x += 0.001;
  })
  return (
    <mesh
      {...props}
      ref={mesh}
			>
			<sphereGeometry args={[2, 100, 40]} attach="geometry" />
      <meshStandardMaterial attach="material" wireframe transparent={true} opacity={0.1} color={'orange'} />
    </mesh>
  )
}

const Scene = () => {
  return (
		<>
			<Canvas style={{position: "absolute", top: 0, left: 0, 	zIndex: -1, background: 'none'}}>
				<ambientLight intensity={0.05} />
				<pointLight position={[10, 30, 10]} />
				<Box position={[2, -1.5, 0]} />
			</Canvas>
		</>
  )
}

export default Scene;