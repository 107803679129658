const title = () => {
	return (
		<>
			<h1 className='title title--name'>Grant Darling</h1>
			<h1 className='title title--job'>Web Developer</h1>
			<h3 className='title title--tagline'>Frontend | FullStack</h3>
		</>
	)
}

export default title;